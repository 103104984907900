.reset-password-card {
  background-color: white;
  padding: 32px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  min-width: 320px;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.logo > img {
  width: 50%;
}
