@import '../../styles/variables.less';

// eatery form styles
.suggestion {
  background-color: @white-color;
  border-bottom: 1px solid @black-color;
  padding: 5px;
  cursor: pointer;
  &-active {
    background-color: #e8f0fe;
    border-bottom: 1px solid @black-color;
    padding: 5px;
    cursor: pointer;
  }
}
