.ant-rate {
  font-size: 14px;
}
.ant-rate-star:not(:last-child) {
  margin-right: 4px;
}
.review-card-city {
  font-weight: 400;
  font-size: 12px;
}
