#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table-column-sorters-with-tooltip {
  padding: 15px;
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid @option-input-border;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid @option-input-border;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid @option-input-border;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid @border-color;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: @card-active;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}
.mr-10 {
  margin-right: 10px;
}

.p-0 {
  padding: 0;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}
.timepicker-width {
  min-width: 200px;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

//CUSTOM STYLE CLASSES END

.suggestion-details-card {
  background: lightgrey;
  width: 350px;
  height: 300px;
}

.ant-descriptions-item-container {
  align-items: center;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.align-self-center {
  align-self: center;
}
.upload-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dotted @border-color;
  border-radius: 8px;
  width: 380px;
  height: 240px;
  cursor: pointer;
  background-color: white;
  object-fit: cover;
}
.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: @background-white;
}
.dish-images {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.eatery-image {
  width: 340px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}
.suggested-image {
  width: auto;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
  border: 1px solid @image-border;
}
.foodie-image {
  width: auto;
  max-height: 125px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
  border: 1px solid @image-border;
}
.suggested-eatery {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}
.delete-img-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  cursor: pointer;
  margin-right: 5px;
  opacity: 1;
  padding: 2px;
  color: @white-color;
  background-color: transparent;
}

.img-options {
  position: absolute;
  right: 0px;
  top: 10px;
  font-size: 25px;
  cursor: pointer;
  margin-right: 5px;
  opacity: 1;
  padding: 2px;
  color: @grey-9;
  background-color: @white-color;
  opacity: 0.6;
  border-radius: 8px;
}

.img-options-hidden {
  display: none;
}

.font-semibold {
  font-weight: 500;
}
.category-description {
  .ant-form-item {
    width: 70%;
  }
}
.margin-b-16 {
  margin-bottom: 1rem;
}
.upload-trigger > img {
  object-fit: cover;
}
.image-cover {
  object-fit: cover;
}
.font-ellipsis {
  white-space: nowrap;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: groove;
}

.tag-items {
  font-size: 15px;
  margin-left: 15px;
  background-color: @white-color;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid @option-input-border;
}

.detail-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form-img {
  margin-top: 2%;
  background-color: white;
  height: 130px;
}

.tag-pointer {
  cursor: pointer;
}

.col-left-margin {
  margin-left: 35px;
}
.ant-layout-header {
  box-shadow: none;
  border-bottom: 1px solid @border-color;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item {
  margin: 18px 0;
}
.ant-menu-item-selected {
  color: @menu-hover;
}
.ant-menu-item:hover {
  color: @menu-hover;
}
.ant-menu-item:active {
  background-color: @secondary-color;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @secondary-color;
}
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid @menu-hover;
}
.ant-layout-sider-children {
  border-right: 1px solid @border-color;
}
.gx-layout-sider-header {
  box-shadow: none;
  border-bottom: 1px solid @border-color;
}
.gx-sidebar-content {
  padding-top: 40px;
  border-right: 0px;
}
.ant-layout-content {
  background-color: @bg-layout;
}
.ml-6 {
  margin-left: 6px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-0 {
  margin-bottom: 0px;
}
.btn-1 {
  background-color: black;
  border-color: black;
  &:hover {
    background-color: @button-hover;
    border-color: @button-hover;
  }
}
.btn-2 {
  background-color: @primary-color;
  border-color: @primary-color;
  &:hover {
    background-color: @menu-hover;
    border-color: @menu-hover;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0 0 0;
}
.ant-pagination-item-active {
  border-color: @primary-color;
  &:hover {
    border-color: @menu-hover;
  }
}
.ant-pagination-item-active a {
  color: @primary-color;
  &:hover {
    color: @menu-hover;
  }
}
.ant-pagination-options-size-changer {
  border-color: @primary-color;
  &:hover {
    border-color: @menu-hover;
  }
}
.ant-checkbox {
  border-color: @primary-color;
  &:hover {
    border-color: @menu-hover;
  }
}
.ant-checkbox-wrapper {
  border-color: @primary-color;
  border-radius: 2px;
  &:hover {
    border-color: @menu-hover;
  }
}
.ant-checkbox-inner {
  border-radius: 2px;
  border-width: 1px;
}
.ant-checkbox-wrapper:hover + .ant-checkbox-inner:hover {
  border-color: @menu-hover;
}
.ant-checkbox-checked::after {
  border-color: @menu-hover;
  border-radius: 2px;
}
.ant-checkbox:hover {
  border-color: @menu-hover;
}
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @menu-hover;
}
.ant-checkbox-input:hover {
  border-color: @menu-hover;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @menu-hover;
  border-color: @menu-hover;
}
.gx-main-content-wrapper {
  padding-bottom: 32px;
}
.ant-btn.ant-btn-default {
  border-color: @menu-hover;
  color: @menu-hover;
  background: @button-bg;
}
.ant-btn.ant-btn-default:hover {
  border-color: @button-border-color;
  color: @button-border-color;
  background: @button-bg;
}
.timepicker-auto {
  height: auto;
  width: auto;
  display: flex;
}
.vertical-hr {
  height: 105vh;
  background-color: @secondary-color;
}
.ant-form-item-label {
  font-weight: 500;
}
.tabs-card {
  .ant-card-body {
    padding-top: 12px;
  }
  .ant-tabs-tab {
    padding: 12px 0px 16px 0px;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    margin-top: 4px;
  }
}
.table-card {
  .ant-card-body {
    padding-top: 4px;
  }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3.5px;
}
h4,
h2,
h1,
h5,
h6,
h3 {
  margin-bottom: 0;
}
&.bb-0.ant-table-wrapper tbody > tr:last-child > td {
  border-bottom: none;
}
.ant-form-item-required {
  flex-direction: row-reverse;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-left: 4px;
}
.rate {
  font-size: 18px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.text-wrap {
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
.ant-col-5 {
  max-width: 100%;
}
.grid-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.grid-child {
  height: 100%;
}
.grid-box-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid-box-3 {
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}
.actions {
  .ant-popover-inner-content {
    padding: 12px 0px;
  }
}
.display-none {
  display: none;
}
.width-full {
  width: 100%;
}
.image-preview {
  width: 100%;
}
